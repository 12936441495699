import { useStaticQuery, graphql } from 'gatsby'

/**
 * Get Checklist and its hierarchy sections.
 * GraphQL doesn't really support recursion, so we are using fragments and manually control level of recursion.
 * Solution is not very DRY, but it solves the issue with enormous app size (when using _raw data and automatic references resolving).
 *
 * Currently recursion is 6 levels deep.
 */
export const useChecklistQuery = () => {
  const { sanityChecklist } = useStaticQuery(
    graphql`
      {
        sanityChecklist {
          id
          title
          contentSections {
            ...ContentSection
            ...HierarchicalRecursiveSection
          }
        }
      }
      fragment HierarchicalRecursiveSection on SanityHierarchicalSection {
        _key
        _type
        description
        topBarTitle
        slug {
          current
        }
        sectionSettings {
          collapsibleSection
          sectionIcon {
            iconSource
          }
          sectionTitle
        }
        sectionContent {
          ...CasesSection
          ...ContentSection
          ...MultiContentSection
          ...SelectedResolutionsSection
          ... on SanityHierarchicalSection {
            _key
            _type
            description
            slug {
              current
            }
            sectionSettings {
              collapsibleSection
              sectionIcon {
                iconSource
              }
              sectionTitle
            }
            sectionContent {
              ...CasesSection
              ...ContentSection
              ...MultiContentSection
              ...SelectedResolutionsSection
              ... on SanityHierarchicalSection {
                _key
                _type
                description
                topBarTitle
                slug {
                  current
                }
                sectionSettings {
                  collapsibleSection
                  sectionIcon {
                    iconSource
                  }
                  sectionTitle
                }
                sectionContent {
                  ...CasesSection
                  ...ContentSection
                  ...MultiContentSection
                  ...SelectedResolutionsSection
                  ... on SanityHierarchicalSection {
                    _key
                    _type
                    description
                    topBarTitle
                    slug {
                      current
                    }
                    sectionSettings {
                      collapsibleSection
                      sectionIcon {
                        iconSource
                      }
                      sectionTitle
                    }
                    sectionContent {
                      ...CasesSection
                      ...ContentSection
                      ...MultiContentSection
                      ...SelectedResolutionsSection
                      ... on SanityHierarchicalSection {
                        _key
                        _type
                        description
                        topBarTitle
                        slug {
                          current
                        }
                        sectionSettings {
                          collapsibleSection
                          sectionIcon {
                            iconSource
                          }
                          sectionTitle
                        }
                        sectionContent {
                          ...CasesSection
                          ...ContentSection
                          ...MultiContentSection
                          ...SelectedResolutionsSection
                          ... on SanityHierarchicalSection {
                            _key
                            _type
                            description
                            topBarTitle
                            slug {
                              current
                            }
                            sectionSettings {
                              collapsibleSection
                              sectionIcon {
                                iconSource
                              }
                              sectionTitle
                            }
                            sectionContent {
                              ...CasesSection
                              ...ContentSection
                              ...MultiContentSection
                              ...SelectedResolutionsSection
                              ... on SanityHierarchicalSection {
                                _key
                                _type
                                description
                                topBarTitle
                                slug {
                                  current
                                }
                                sectionSettings {
                                  collapsibleSection
                                  sectionIcon {
                                    iconSource
                                  }
                                  sectionTitle
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      fragment CasesSection on SanityCasesSection {
        _key
        _type
        episodes {
          id
          episodeTitle
          episodeNumber
          startDate
          endDate
          path
          slug {
            current
          }
          inCase {
            endDate
            startDate
            title
            id
            slug {
              current
            }
          }
        }
        sectionSettings {
          collapsibleSection
          sectionIcon {
            iconSource
          }
          sectionTitle
        }
      }

      fragment ContentSection on SanityContentSection {
        _key
        _type
        _rawSectionContent
        sectionSettings {
          collapsibleSection
          sectionIcon {
            iconSource
          }
          sectionTitle
        }
      }

      fragment MultiContentSection on SanityMultiContentSection {
        _key
        _type
        resolutionSections {
          _key
          title
          resolutionSections {
            _key
            _rawContent
            paragraph
            resolutionName
          }
        }
        sectionSettings {
          collapsibleSection
          sectionIcon {
            iconSource
          }
          sectionTitle
        }
      }

      fragment SelectedResolutionsSection on SanitySelectedResolutionsSection {
        _key
        _type
        resolutions {
          _key
          resolutionName
        }
        sectionSettings {
          collapsibleSection
          sectionIcon {
            iconSource
          }
          sectionTitle
        }
      }
    `
  )
  return sanityChecklist
}
