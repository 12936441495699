import React from 'react'

import { useChecklistQuery } from '../hooks/useChecklistQuery'

import SEO from '../components/seo'
import Layout from '../components/layout'
import NonExpansiblePanel from '../mui-components/NonExpansiblePanel/NonExpansiblePanel'
import SectionsChooser from '../../src/components/sections-chooser'

const ChecklistTemplate = props => {
  const { pageContext } = props

  const checklistData = useChecklistQuery()

  let contentData = []

  // Loop through all checklist sections and find content section for current checklist page
  const currentContentSections = (arr, parent) => {
    arr.forEach(item => {
      const slug = item.slug ? item.slug.current : item._key

      // Add custom slug which will be used on nested hierarchy links
      if (parent && parent['nestedSlug']) {
        item['nestedSlug'] = parent['nestedSlug'] + '/' + slug
      } else {
        if (item._type === 'hierarchicalSection') {
          item['nestedSlug'] = slug
        }
      }

      if (item._type === 'hierarchicalSection') {
        // Get proper content sections for current checklist page
        if (item._key === pageContext.key) {
          contentData = item.sectionContent
        }

        // Check if has nested sections
        if (typeof item.sectionContent === 'object' && Object.keys(item).length) {
          currentContentSections(item.sectionContent, item)
        }
      }
    })
  }

  // Get all hierarchical sections from checklist
  currentContentSections(checklistData.contentSections)

  let title = pageContext.customTopbarTitle ? pageContext.customTopbarTitle : ''
  const sectionDescription = pageContext.sectionDescription ? pageContext.sectionDescription : ''
  const currentTitle = pageContext.sectionSettings ? pageContext.sectionSettings.sectionTitle : ''
  return (
    <Layout title={title} {...props}>
      <SEO title={title} description={sectionDescription} />
      <NonExpansiblePanel title={currentTitle} highlight>
        {sectionDescription}
      </NonExpansiblePanel>
      <SectionsChooser sections={contentData} />
    </Layout>
  )
}

export default ChecklistTemplate
